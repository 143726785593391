import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const NavBar = (props: any) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      {' '}
      <NavBarContainer {...props}>
        <Link to={'/'}>
          <Heading
            display={{ base: isOpen ? 'none' : 'block', md: 'block' }}
            fontSize={{ base: '5vw', md: '2vw' }}
            flex={{ base: 'auto auto auto' }}
            transform={{ base: 'translateX(5%)' }}
          >
            Toft Ejendomme
          </Heading>
        </Link>
        <MenuLinks isOpen={isOpen} toggle={toggle} />
        <MenuToggle toggle={toggle} isOpen={isOpen} first={true} />
      </NavBarContainer>
    </>
  );
};

const MenuItem = (props: any) => {
  if (props.external) {
    return (
      <Link
        to={props.to}
        target="_blank"
      >
        <MenuText>{props.children}</MenuText>
      </Link>
    );
  }

  return (
    <Link to={props.to} onClick={props.toggle}>
      <MenuText>{props.children}</MenuText>
    </Link>
  );
};

const MenuText = (props: any) => {
  return (
    <Text
      display="block"
      fontSize={{ base: '7vw', md: '1.3vw' }}
      style={{ fontWeight: 'normal' }}
    >
      {props.children}
    </Text>
  );
};

const MenuLinks = (props: any) => {
  return (
    <Box
      paddingTop={{ base: '4vw', md: '0' }}
      display={{ base: props.isOpen ? 'block' : 'none', md: 'block' }}
      bg={{ base: 'white', md: 'transparent' }}
      paddingBottom={{ base: '4vw', md: '0' }}
      zIndex={20}
      w={{ base: '100%', md: 'unset' }}
    >
      <Stack
        spacing={[5, '5vw']}
        justify={{ base: 'center', md: 'space-between' }}
        direction={{ base: 'column', md: 'row' }}
        align={'center'}
      >
        <MenuToggle toggle={props.toggle} isOpen={props.isOpen} />
        <MenuItem to="/" toggle={props.toggle}>
          Forside
        </MenuItem>
        <MenuItem to="/ejendomme" toggle={props.toggle}>
          Ejendomme
        </MenuItem>
        <MenuItem
          to={{ pathname: "https://www.boligportal.dk/dl/toft/" }}
          external={true}
          toggle={props.toggle}
        >
          Ledige Lejemål
        </MenuItem>
        <MenuItem to="/lejerinfo" toggle={props.toggle}>
          Lejer Info
        </MenuItem>
        <MenuItem to="/kontakt" toggle={props.toggle}>
          Kontakt
        </MenuItem>
      </Stack>
    </Box>
  );
};

const NavBarContainer = (props: any) => {
  return (
    <Flex
      height="60px"
      bg={'toftejendomme.400'}
      width={'100%'}
      wrap="wrap"
      justifyContent={['flex-start', 'space-between']}
      align={'center'}
    >
      {props.children}
    </Flex>
  );
};

const MenuToggle = ({
  toggle,
  isOpen,
  first = false,
}: {
  toggle: any;
  isOpen: any;
  first?: boolean;
}) => {
  const baseVisibility = first ? (isOpen ? 'none' : 'block') : 'block';
  return (
    <Box
      display={{ base: baseVisibility, md: 'none' }}
      ml={'auto'}
      mr={'10px'}
      flex={{ base: 'auto auto auto' }}
      onClick={toggle}
    >
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </Box>
  );
};

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="black"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="black"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

export default NavBar;
