import { Flex, Text, useDisclosure } from '@chakra-ui/react';
import { PrivatlivsPolitik } from '../PrivatlivsPolitik';

const Footer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      mt={'auto'}
      w={'100%'}
      bg={'toftejendomme.400'}
      align={'center'}
      justifyContent={'center'}
      direction={'row'}
    >
      <Text opacity="0.5" margin="5">
        Copyright &#169; Toft Ejendomme 2024
      </Text>

      <Text
        cursor={'pointer'}
        onClick={onOpen}
        opacity="0.5"
        margin="5"
      >
        Behandling af personlige oplysninger
      </Text>
      <PrivatlivsPolitik isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </Flex>
  );
};

export default Footer;
