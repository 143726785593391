import {
  Box,
  Divider,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Skeleton,
  useColorModeValue,
} from '@chakra-ui/react';
import type { FC } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import { fetchProperties } from '../components/data/SWR';
import useGaTracker from '../components/PageTracking';
import { strapiUrl } from '../config/env';

interface PropertyProps {
  ejendom: {
    Adresse: string;
    Antal_Lejemaal: number;
    id: number;
    Billede: {
      formats: {
        thumbnail: {
          url: string;
        };
        small: {
          url: string;
        };
        medium: {
          url: string;
        };
        large: {
          url: string;
        };
      };
      url: string;
    };
    Bygget: string;
  };
}

const Ejendomme: FC = () => {
  const propetiesResponse = fetchProperties();
  useGaTracker();

  return (
    <>
      <Flex
        pr={'100px'}
        pl={'100px'}
        w={'100%'}
        direction={'column'}
        mt={'20px'}
      >
        <Heading
          m={'auto'}
          mb={'20px'}
          fontWeight={'normal'}
          fontStyle={'normal'}
          fontSize={['8vw', '4vw']}
        >
          Ejendomme
        </Heading>
        <ColoredLine />
        <SimpleGrid minChildWidth={['30vw', '370px']} mt="50px" spacing={30}>
          {propetiesResponse.data &&
            propetiesResponse.data.Ejendom.map((property: PropertyProps) => {
              return (
                <Skeleton
                  key={property.ejendom.id}
                  isLoaded={!propetiesResponse.isLoading}
                >
                  <PropertyCard
                    imageUrl={`${strapiUrl}${property?.ejendom.Billede?.formats.small.url}`}
                    thumbnailUrl={`${strapiUrl}${property?.ejendom.Billede?.formats.thumbnail.url}`}
                    propertyTitle={property.ejendom.Adresse}
                    navigateTo={`/ejendom/${property.ejendom.id}`}
                    property={property}
                  />
                </Skeleton>
              );
            })}
        </SimpleGrid>
      </Flex>
    </>
  );
};

interface PropertyCardProps {
  imageUrl: string;
  thumbnailUrl: string;
  propertyTitle: string;
  navigateTo: string;
  property: PropertyProps;
}

const PropertyCard = (props: PropertyCardProps) => {
  const to = {
    pathname: props.navigateTo,
    state: props.property,
  };
  return (
    <Link to={to}>
      <Flex alignItems="center" justifyContent="center">
        <Box
          maxWidth={'335px'}
          maxHeight={'350px'}
          minWidth={'380px'}
          minHeight={'335px'}
          bg={useColorModeValue('white', 'gray.800')}
          borderWidth="1px"
          rounded="lg"
          shadow="lg"
        >
          <Image
            width={'100%'}
            height={'245px'}
            bg={'gray.200'}
            src={props.imageUrl}
            alt={`Picture of ${props.propertyTitle}`}
            roundedTop="lg"
            objectFit={'cover'}
          />

          <Box>
            <Flex
              mt="2"
              ml="3"
              justifyContent="space-between"
              alignContent="center"
              direction={'column'}
            >
              <Box fontSize="22" fontWeight="regular" as="h4" lineHeight="none">
                {props.propertyTitle}
              </Box>
              <Box
                fontSize="16"
                fontWeight="regular"
                as="h4"
                lineHeight="none"
                opacity={'0.5'}
                mt={'5px'}
              >
                8600 Silkeborg
              </Box>
            </Flex>
          </Box>

          <Flex mt="5px" justifyContent="" alignContent="center">
            <Box
              ml="3"
              fontSize="14"
              fontWeight="regular"
              as="h4"
              lineHeight="tight"
              isTruncated
            >
              {`${props.property.ejendom.Antal_Lejemaal} lejemål`}
            </Box>
            <Divider
              ml={'5px'}
              mr={'5px'}
              color={'black'}
              mt={'3px'}
              orientation="vertical"
              h={'9px'}
            />
            <Box
              fontSize="14"
              fontWeight="regular"
              as="h4"
              lineHeight="tight"
              isTruncated
            >
              {`Opført ${props.property.ejendom.Bygget}`}
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Link>
  );
};

export default Ejendomme;

export const ColoredLine = () => (
  <hr
    style={{
      color: 'black',
      backgroundColor: 'black',
      height: 1,
    }}
  />
);
