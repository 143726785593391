import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useBreakpoint,
  useDisclosure,
} from '@chakra-ui/react';
import type { FC } from 'react';
import '../App.scss';
import placement from '../assets/beliggenhed.png';
import estrupsgadeGammel from '../assets/estrupsgade-gammel.png';
import quality from '../assets/kvalitet.png';
import lol from '../assets/bed.jpg';
import top from '../assets/top.webp';
import useGaTracker from '../components/PageTracking';
import { SignUpForApartment } from '../components/SignUpForApartment';
import WithSpeechBubbles from '../components/bubbles';
import { Link } from 'react-router-dom';

const Home: FC = () => {
  const breakpoint = useBreakpoint();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useGaTracker();

  return (
    <>
      <Box
        bgImg={top}
        bgSize={'cover'}
        h={['70vw', '45vw']}
        w={'100%'}
        borderRadius={[0, '8px']}
        boxShadow="lg"
      >
        <Box ml={['15vw', '10']} mt={['20vw', '26vw']}>
          <Heading
            color={'white'}
            fontSize={['8vw', '4vw']}
            width={['70vw', '40vw']}
            textShadow="2px 2px 4px rgba(0, 0, 0, 0.5)"
          >
            Se vores ledige boliger i Silkeborg
          </Heading>
          <Link
            to={{ pathname: 'https://www.boligportal.dk/dl/toft/' }}
            target="_blank"
          >
            <Button
              rightIcon={<ArrowForwardIcon />}
              mt={['1vw', '1vw']}
              w={['45vw', '15vw']}
              h={['8vw', '2vw']}
              borderColor={'black'}
              fontSize={['2.6vw', '1.0vw']}
              boxShadow="md"
              _hover={{ transform: 'scale(1.05)', transition: '0.3s' }}
            >
              Se ledige boliger
            </Button>
          </Link>
        </Box>
      </Box>

      <Flex
        mr={{ md: '5vw' }}
        ml={{ md: '5vw' }}
        flexDirection={'column'}
        mt={{ md: '2vw' }}
      >


        {breakpoint !== 'base' ? (
          <Flex
            mt={{ md: '2vw' }} direction={['column', 'row']} h={{ md: '35vw' }} >
            <Box>
              <Image
                src={estrupsgadeGammel}
                objectFit={'cover'}
                h={['100vw', '100%']}
                w={['100%', '70vw']}
                borderLeftRadius={{ md: 8 }}
                boxShadow="lg"
              />
            </Box>

            <Flex
              bg={'#A39E90'}
              flexDir={'column'}
              py={['6vw', '0vw']}
              borderRightRadius={{ md: 8 }}
              boxShadow="lg"
            >
              <Heading
                fontSize={['8vw', '1.8vw']}
                mb={'1.5vw'}
                mt={'auto'}
                textAlign={'center'}
                w={'100%'}
                mx={'auto'}
              >
                Velkommen til Toft Ejendomme
              </Heading>
              <Text
                mx={'auto'}
                mb={'auto'}
                align="center"
                w={['100%', '50%']}
                fontSize={['5vw', '1.2vw']}
              >
                Toft Ejendomme råder over et samlet boligareal på 8000 m2
                fordelt på 120 boliglejemål.
                <br />
                <br />
                Alle lejemål har attraktive
                placeringer i det centrale Silkeborg, med kort afstand til
                gågadens mange tilbud, offentlige transportmidler samt den
                flotte natur i og omkring Silkeborg.
                <br />
                <br />
                Toft Ejendomme blev tilbage
                i 1960erne etableret af malermester Henning Toft.
              </Text>
            </Flex>
          </Flex>
        ) : (
          <Flex
            direction={['column', 'row']}
            h={{ md: '25vw' }}
            justifyContent="center"
            alignItems="center"
          >
            <Flex
              bg={'#A39E90'}
              flexDir={'column'}
              py={['6vw', '0vw']}
              borderRightRadius={{ md: 8 }}
              textAlign="center"
              boxShadow="lg"
            >
              <Heading fontSize={['8vw', '1.8vw']} mb={'1.5vw'} mt={'auto'} pt={'2vw'}>
                Velkommen til Toft Ejendomme
              </Heading>
              <Text
                mx={'auto'}
                mb={'auto'}
                align="center"
                w={['80%', '50%']}
                fontSize={['5vw', '1.2vw']}
              >
                Toft Ejendomme råder over et samlet boligareal på 8000 m2
                fordelt på 120 boliglejemål.
                <br />
                <br />
                Alle lejemål har attraktive
                placeringer i det centrale Silkeborg, med kort afstand til
                gågadens mange tilbud, offentlige transportmidler samt den
                flotte natur i og omkring Silkeborg.
                <br />
                <br />
                Toft Ejendomme blev tilbage
                i 1960erne etableret af malermester Henning Toft.
              </Text>
            </Flex>
            <Box>
              <Image
                src={estrupsgadeGammel}
                objectFit={'cover'}
                h={['100vw', '100%']}
                w={['100%', '70vw']}
                borderLeftRadius={{ md: 8 }}
                boxShadow="lg"
              />
            </Box>
          </Flex>
        )}

        <Flex
          flexDir={['column', 'row']}
          alignItems="center"
          bg={'#E2E8F0'}
          borderRadius={{ md: '8px' }}
          p={['15vw', '2vw']}
          boxShadow="lg"
          mt={{ md: '2vw' }}
        >
          <Box textAlign={'center'} w={'100%'}>
            <Heading
              fontSize={['8vw', '2vw']}
              fontWeight={'bold'}
              textColor={'black'}
            >
              Vi køber udlejningsejendomme
            </Heading>
            <Text
              mt={'1vw'}
              fontSize={['5vw', '1.2vw']}
              textColor={'black'}
              maxW={'80%'}
              mx={'auto'}
            >
              Har du en udlejningsejendom, som du ønsker at sælge?
            </Text>
            <Text
              mt={'1vw'}
              fontSize={['5vw', '1.2vw']}
              textColor={'black'}
              maxW={'80%'}
              mx={'auto'}
            >
              Vi er altid interesserede i at udvide vores portefølje og køber gerne ejendomme i Silkeborg og omegn.
            </Text>
            <Link to="/kontakt">
              <Button
                mt={['10vw', '2vw']}
                w={['45vw', '15vw']}
                h={['8vw', '2vw']}
                borderColor={'black'}
                fontSize={['2.6vw', '1.0vw']}
                boxShadow="md"
                _hover={{ transform: 'scale(1.05)', transition: '0.3s' }}
              >
                Kontakt os
              </Button>
            </Link>
          </Box>
        </Flex>

        <Flex
          mt={{ md: '2vw' }}
          flexDir={['column', 'row']}
          alignItems="center"
        >
          <Flex
            bg={'#D1CABD'}
            borderRadius={{ md: '8px' }}
            direction={'column'}
            h={['100vw', '35vw']}
            w={['100%', '50%']}
            justifyContent="center"
            boxShadow="lg"
          >
            <Box m={'auto'} textAlign={'center'}>
              <Heading
                textColor={'black'}
                fontSize={['8vw', '1.8vw']}
                fontWeight={'bold'}
                w={'80%'}
                mx={'auto'}
              >
                Søger du en ny bolig?
              </Heading>
              <Text
                mt={'1vw'}
                textColor={'black'}
                align={'center'}
                fontSize={['5vw', '1.2vw']}
                maxW={'50%'}
                mx={'auto'}
              >
                Hos Toft Ejendomme kan du gratis søge og finde ledige
                lejeboliger i Silkeborg Midtby.
                <br /> <br />
                {breakpoint !== 'base'
                  ? 'Toft Ejendomme tilbyder unikke boliger med plads til alle. Drømmer du om at bo i centrum af Silkeborg?'
                  : ''}
              </Text>
              <Button
                onClick={onOpen}
                m={'20px'}
                w={'50%'}
                borderColor={'black'}
                fontSize={['2.6vw', '1.0vw']}
                boxShadow="md"
                mx={'auto'}
                _hover={{ transform: 'scale(1.05)', transition: '0.3s' }}
              >
                Skriv dig op
              </Button>
            </Box>
          </Flex>
          <Image
            w={['100vw', '50%']}
            h={['100vw', '30vw']}
            objectFit={'cover'}
            src={lol}
            borderRightRadius={{ md: 8 }}
            boxShadow="lg"
          />
        </Flex>

        <WithSpeechBubbles />

        <Flex
          mt={{ md: '2vw' }}
          flexBasis={'25%'}
          flexGrow={0}
          direction={{ base: 'column', md: 'row' }}
        >
          <Box
            borderRadius={{ md: '8px' }}
            bgImg={quality}
            bgSize={['auto', 'cover']}
            bgRepeat={'no-repeat'}
            w={['100vw', '100%']}
            h={['90vw', '28vw']}
            boxShadow="lg"
          >
            <Box m={'auto'} mt={['16vw', '0']} textAlign={['center', 'left']}>
              <Heading
                fontSize={{ base: '10vw', md: '2vw' }}
                ml={{ md: '1.5vw' }}
                mt={{ md: '0.7vw' }}
                fontWeight={'semibold'}
              >
                Kvalitet
              </Heading>
              <Text
                ml={{ md: '1.5vw' }}
                mt={'0.5vw'}
                w={['70%', '15vw']}
                m="auto"
                fontSize={['5.4vw', '1.2vw']}
              >
                Vi stræber efter at vedligeholde vores ejendomme i den bedste
                kvalitet. Alle vores lejligheder fremstår flotte og
                velrenoveret.
              </Text>
            </Box>
          </Box>

          <Box w={'2vw'} />
          <Box
            borderRadius={{ md: '8px' }}
            bgImg={placement}
            bgSize={['auto', 'cover']}
            bgRepeat={'no-repeat'}
            w={['100vw', '100%']}
            h={['90vw', '28vw']}
            boxShadow="lg"
          >
            <Box m={'auto'} mt={['16vw', '0']} textAlign={['center', 'left']}>
              <Heading
                fontSize={{ base: '10vw', md: '2vw' }}
                ml={{ md: '1.5vw' }}
                mt={{ md: '0.7vw' }}
                fontWeight={'semibold'}
                color={'white'}
              >
                Beliggenhed
              </Heading>
              <Text
                ml={{ md: '1.5vw' }}
                mt={'0.5vw'}
                w={['70%', '15vw']}
                m="auto"
                fontSize={['5.4vw', '1.2vw']}
                color={'white'}
              >
                Vores ejendomme ligger på de bedste beliggenheder i Silkeborg.
                Vi har ejendomme i Silkeborgs midt- og sydby. Ejendommene har
                kort afstand til midtbyens tilbud.
              </Text>
            </Box>
          </Box>
        </Flex>
      </Flex>
      <SignUpForApartment isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </>
  );
};

export default Home;
